import { FC, PropsWithChildren } from 'react';
import { Pie, PieTooltipProps } from '@nivo/pie';
import { City } from 'services/api';
import { getThemeColor, themeColors, legacyTheme } from 'styles/theme';
import { Typography } from 'core/typography';
import { FlexContainer } from 'layout/flex-container';
import {
  StyledChartTooltipColor,
  StyledChartTooltipColorContainer,
} from '../stats-tooltip/stats-tooltip.styles';
import { StyledChartLegend } from '../../stats.styles';
import { CitiesChartPresets } from './cities-chart.presets';
import { CitiesChartProps } from './cities-chart.props';
import { getChartColor } from '../../stats.helpers';

export const CitiesChart: FC<CitiesChartProps> = (props) => {
  const { cities } = { ...CitiesChartPresets, ...props };

  return (
    <FlexContainer
      alignItems="center"
      direction="column"
      justifyContent="center"
    >
      <Pie
        activeOuterRadiusOffset={8}
        animate
        colors={({ data }) => data.color}
        data={cities.map((city: City, index: number) => ({
          color: getChartColor(themeColors[index] || 'primary'),
          id: city.city,
          name: city.city,
          value: city.percent,
        }))}
        enableArcLabels={false}
        enableArcLinkLabels={false}
        height={200}
        innerRadius={0.6}
        margin={{ bottom: 30, left: 30, right: 30, top: 30 }}
        tooltip={(
          data: PropsWithChildren<
            PieTooltipProps<{ color: string; value: number }>
          >,
        ) => (
          <StyledChartTooltipColorContainer>
            <StyledChartTooltipColor color={data.datum.color} />
            &nbsp;
            <Typography
              backgroundColor="white"
              text={`${data.datum.value || 0}% (${data.datum.id})`}
            />
          </StyledChartTooltipColorContainer>
        )}
        width={200}
      />

      <div>
        {cities.map((city: City, index: number) => (
          <StyledChartLegend key={city.city}>
            <StyledChartTooltipColor
              color={getThemeColor(themeColors[index] || 'primary')}
            />
            <Typography text={`${city.percent || 0}%`} textStyle="bold" />
            <Typography color="grey" text={city.city} />
          </StyledChartLegend>
        ))}
      </div>
    </FlexContainer>
  );
};
