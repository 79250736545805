import styled, { css } from 'styled-components';
import {
  calculateDimensionsFromAspectRatio,
  extractValuesFromResolutionAwareProp,
  generateStylesForResolutionAwareProps,
} from 'helpers/layout.helpers';
import { media } from 'styles/media';
import { ExtractStyleFromResolutionAwarePropEntry } from 'types/layout';
import { getThemeColor, legacyTheme } from 'styles/theme';
import { SizedContainerProps } from './sized-container.props';

const populateAspectRatioStylesByResolution = (aspectRatio, resolution) => {
  if (aspectRatio) {
    const { height } = calculateDimensionsFromAspectRatio(aspectRatio);

    return media.lessThan(resolution)`
        height: inherit;
        padding-top: ${height};
      `;
  }
  return '';
};

export const populateAspectRatioStyles = (aspectRatio) => {
  if (aspectRatio) {
    const {
      lg: aspectRatioLg,
      md: aspectRatioMd,
      sm: aspectRatioSm,
    } = extractValuesFromResolutionAwareProp(aspectRatio);

    let aspectRatioStyles;

    if (aspectRatioLg) {
      const { height } = calculateDimensionsFromAspectRatio(aspectRatioLg);

      aspectRatioStyles = css`
        height: inherit;
        padding-top: ${height};
      `;
    }

    return css`
      ${aspectRatioStyles}
      ${populateAspectRatioStylesByResolution(aspectRatioMd, 'md')}
      ${populateAspectRatioStylesByResolution(aspectRatioSm, 'sm')}
    `;
  }
  return css`
    height: 100%;
  `;
};

export const StyledSizedContainer = styled.div<SizedContainerProps>`
  ${({ aspectRatio, backgroundColor, height, hidden, width }) => {
    const entries: ExtractStyleFromResolutionAwarePropEntry[] = [
      {
        cssProperty: 'width',
        resolutionAwareProp: width,
      },
      {
        cssProperty: 'height',
        resolutionAwareProp: height,
      },
      {
        cssProperty: 'background-color',
        resolutionAwareProp: backgroundColor,
        valueFormatter: (value) => getThemeColor(value),
      },
      {
        cssProperty: 'display',
        resolutionAwareProp: hidden,
        valueFormatter: (value) => (value === true ? 'none' : 'block'),
      },
    ];

    return css`
      position: relative;
      background-position: center;
      ${generateStylesForResolutionAwareProps(entries)};
      ${aspectRatio && populateAspectRatioStyles(aspectRatio)};
    `;
  }}
`;

export const StyledSizedContainerContent = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
`;
