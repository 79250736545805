import { FC } from 'react';
import { getThemeColor, legacyTheme } from 'styles/theme';
import { formatCurrency } from 'utils/formaters';
import { Typography } from 'core/typography';
import { formatPstDate } from 'utils/date';
import {
  StyledChartTooltip,
  StyledChartTooltipColor,
  StyledChartTooltipColorContainer,
  StyledChartTooltipContainer,
} from './stats-tooltip.styles';
import { StatsTooltipProps } from './stats-tooltip.types';

export const DATE_FORMAT_BY_GROUP = {
  month: 'MMMM YYYY',
  week: 'MMMM DD, YYYY',
  year: 'YYYY',
};

export const StatsTooltip: FC<StatsTooltipProps> = (props) => {
  const { data, group, index, type } = props;

  const items = Object.keys(data).filter(
    (key) =>
      key !== 'date' && !key.endsWith('Color') && !key.endsWith('Tooltip'),
  );

  return (
    <StyledChartTooltip position={index}>
      <Typography
        color="grey"
        marginBottom="1rem"
        text={formatPstDate(
          data.date,
          DATE_FORMAT_BY_GROUP[group],
        ).toUpperCase()}
      />

      <StyledChartTooltipContainer multipleColumns={items?.length > 6}>
        {items
          .filter((item) => !!data[item])
          .reverse()
          .map((item) => (
            <StyledChartTooltipColorContainer key={item}>
              <StyledChartTooltipColor
                color={getThemeColor(data[`${item}Color`])}
              />
              {type === 'currency'
                ? formatCurrency(data[item] * 100)
                : data[item].toLocaleString()}
              &nbsp;
              <Typography
                color="grey"
                text={data[`${item}Tooltip`]}
                variant={items.length > 6 ? 'p3' : 'p2'}
              />
            </StyledChartTooltipColorContainer>
          ))}
      </StyledChartTooltipContainer>
    </StyledChartTooltip>
  );
};
