import { useEffect, useState } from 'react';
import { isObject } from 'lodash';
import { useTranslation } from 'react-i18next';
import { Typography } from 'core/typography';
import {
  StyledChevronDown,
  StyledSelect,
  StyledSelectContainer,
  StyledSelectPlaceholder,
} from './select-input.styles';
import { SelectInputPresets } from './select-input.presets';
import { SelectInputProps } from './select-input.props';

export const SelectInput = (props: SelectInputProps) => {
  const {
    autoComplete,
    error,
    errorTx,
    id,
    label,
    labelProperty,
    labelTx,
    name,
    onBlur,
    onChange,
    onNativeChange,
    options,
    placeholder,
    placeholderTx,
    shouldTranslateOption,
    touched,
    value,
    valueProperty,
  } = { ...SelectInputPresets, ...props };
  const [innerValue, setInnerValue] = useState(undefined);

  const { t } = useTranslation();

  useEffect(() => {
    setInnerValue(value);
  }, [value]);

  const onOptionSelected = (event) => {
    const {
      target: { value },
    } = event;

    const complex = isObject(options[0]);
    const selectedValue = options.find((option) => {
      return complex ? option[valueProperty] === value : option === value;
    });

    if (onChange) {
      onChange(complex ? selectedValue[valueProperty] : selectedValue);
    }

    if (onNativeChange) {
      onNativeChange(event);
    }

    setInnerValue(complex ? selectedValue[valueProperty] : selectedValue);
  };

  return (
    <StyledSelectContainer invalid={(error || errorTx) && touched}>
      {(label || labelTx) && (
        <Typography text={label} tx={labelTx} variant="label" />
      )}

      <StyledSelect
        autoComplete={autoComplete}
        id={id}
        name={name}
        value={innerValue}
        onBlur={onBlur}
        onChange={onOptionSelected}
      >
        {!innerValue && (placeholderTx || placeholder) && <option />}
        {options.map((option, index: number) => {
          const value = isObject(option) ? option[valueProperty] : option;
          const text = isObject(option) ? option[labelProperty] : option;

          return (
            <option key={value || index} value={value}>
              {shouldTranslateOption ? t(text) : text}
            </option>
          );
        })}
      </StyledSelect>

      {!innerValue && (placeholderTx || placeholder) && (
        <StyledSelectPlaceholder invalid={(error || errorTx) && touched}>
          {placeholderTx ? t(placeholderTx) : placeholder}
        </StyledSelectPlaceholder>
      )}
      <StyledChevronDown name="chevron-down" size="3rem" />
    </StyledSelectContainer>
  );
};
