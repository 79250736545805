import styled, { css } from 'styled-components';
import { generateStylesForResolutionAwareProps } from 'helpers/layout.helpers';
import { ExtractStyleFromResolutionAwarePropEntry } from 'types/layout';
import { getThemeColor, legacyTheme } from 'styles/theme';
import { BoxContainerProps } from './box-container.props';

export const StyledBoxContainer = styled.div<BoxContainerProps>`
  ${(props) => {
    const {
      backgroundColor,
      padding,
      verticalPadding,
      horizontalPadding,
      paddingLeft = horizontalPadding,
      paddingRight = horizontalPadding,
      paddingTop = verticalPadding,
      paddingBottom = verticalPadding,
      margin,
      verticalMargin,
      horizontalMargin,
      marginLeft = horizontalMargin,
      marginRight = horizontalMargin,
      marginTop = verticalMargin,
      marginBottom = verticalMargin,
      theme,
      borderRadius,
      hidden,
      textAlign,
      height,
      minHeight,
      width,
      minWidth,
      maxWidth,
      overflow,
      alignSelf,
      justifySelf,
    } = props;

    const entries: ExtractStyleFromResolutionAwarePropEntry[] = [
      {
        cssProperty: 'background-color',
        resolutionAwareProp: backgroundColor,
        valueFormatter: (color) => getThemeColor(color),
      },
      {
        cssProperty: 'padding',
        lookIn: theme.spacing,
        resolutionAwareProp: padding,
      },
      {
        cssProperty: 'padding-left',
        lookIn: theme.spacing,
        resolutionAwareProp: paddingLeft,
      },
      {
        cssProperty: 'padding-right',
        lookIn: theme.spacing,
        resolutionAwareProp: paddingRight,
      },
      {
        cssProperty: 'padding-top',
        lookIn: theme.spacing,
        resolutionAwareProp: paddingTop,
      },
      {
        cssProperty: 'padding-bottom',
        lookIn: theme.spacing,
        resolutionAwareProp: paddingBottom,
      },
      {
        cssProperty: 'margin',
        lookIn: theme.spacing,
        resolutionAwareProp: margin,
      },
      {
        cssProperty: 'margin-left',
        lookIn: theme.spacing,
        resolutionAwareProp: marginLeft,
      },
      {
        cssProperty: 'margin-right',
        lookIn: theme.spacing,
        resolutionAwareProp: marginRight,
      },
      {
        cssProperty: 'margin-top',
        lookIn: theme.spacing,
        resolutionAwareProp: marginTop,
      },
      {
        cssProperty: 'margin-bottom',
        lookIn: theme.spacing,
        resolutionAwareProp: marginBottom,
      },
      {
        cssProperty: 'display',
        resolutionAwareProp: hidden,
        valueFormatter: (value) => (value === true ? 'none' : 'block'),
      },
      {
        cssProperty: 'border-radius',
        resolutionAwareProp: borderRadius,
      },
      {
        cssProperty: 'text-align',
        resolutionAwareProp: textAlign,
      },
      {
        cssProperty: 'width',
        resolutionAwareProp: width,
      },
      {
        cssProperty: 'height',
        resolutionAwareProp: height,
      },
      {
        cssProperty: 'min-height',
        resolutionAwareProp: minHeight,
      },
      {
        cssProperty: 'min-width',
        resolutionAwareProp: minWidth,
      },
      {
        cssProperty: 'max-width',
        resolutionAwareProp: maxWidth,
      },
      {
        cssProperty: 'align-self',
        resolutionAwareProp: alignSelf,
      },
      {
        cssProperty: 'justify-self',
        resolutionAwareProp: justifySelf,
      },
    ];

    return css`
      position: relative;
      overflow: ${overflow};
      ${generateStylesForResolutionAwareProps(entries)};
    `;
  }}
`;
