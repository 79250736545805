import { FC } from 'react';
import { Grid } from 'layout/grid';
import { Cell } from 'layout/cell';
import { FlexContainer } from 'layout/flex-container';
import { BoxContainer } from 'layout/box-container';
import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownMenuDivider,
} from 'core/dropdown';
import { Typography } from 'core/typography';
import { Link } from 'react-router-dom';
import { Icon } from 'core/icon/icon';
import { StackedContainer } from 'layout/stacked-container';
import { useLayout } from 'context/layout.context';
import { TypographyWithIcon } from 'core/typography-with-icon';
import { environment } from 'config/environment';
import { getFileUrl, Image } from 'gantri-components';
import { routePaths } from 'src/app-routes';
import { legacyTheme } from 'styles/theme';
import { NotificationsOverlay } from './components';
import {
  StyledDesignerLogoContainer,
  StyledMenuIconContainer,
  StyledMenuIconLine,
} from './menu.styles';
import { MenuProps } from './menu.props';

export const Menu: FC<MenuProps> = (props) => {
  const { onLogout, onMenuButtonClick, userData } = props;

  const {
    layout: {
      header: { height: headerHeight, heightSmaller: headerHeightSmaller },
    },
  } = legacyTheme;

  const { sideMenuVisible } = useLayout();

  const menu = (
    <DropdownMenu width="23.8rem">
      <DropdownItem to={`${environment.SHOP_URL}/account/settings`}>
        <Typography color="grey" tx="shopMenu.settings" />
      </DropdownItem>

      <DropdownItem to={`${environment.SHOP_URL}/account/addresses`}>
        <Typography color="grey" tx="shopMenu.addresses" />
      </DropdownItem>

      <DropdownItem to={`${environment.SHOP_URL}/account/payments`}>
        <Typography color="grey" tx="shopMenu.payments" />
      </DropdownItem>

      <DropdownItem to={`${environment.SHOP_URL}/account/wishlist`}>
        <StackedContainer
          alignItems="center"
          columns="repeat(3, max-content)"
          gap="6px"
          padding="unset"
        >
          <Typography color="grey" tx="shopMenu.wishlist" />
          {!!userData?.wishlist?.length && (
            <>
              <Icon color="gold" name="star" size="1.8rem" />
              <Typography
                color="gold"
                text={userData.wishlist.length}
                variant="p3"
              />
            </>
          )}
        </StackedContainer>
      </DropdownItem>

      <DropdownItem to={`${environment.SHOP_URL}/account/orders`}>
        <Typography color="grey" tx="shopMenu.orders" />
      </DropdownItem>

      <DropdownMenuDivider />

      <DropdownItem to={`${environment.SHOP_URL}`}>
        <Typography color="grey" tx="shopMenu.shop" />
      </DropdownItem>

      <DropdownItem onClick={onLogout}>
        <Typography color="grey" tx="shopMenu.logout" />
      </DropdownItem>
    </DropdownMenu>
  );

  return (
    <Grid
      alignContent="center"
      alignItems="center"
      columns={2}
      gap="unset"
      height={{ lg: headerHeight, sm: headerHeightSmaller }}
      horizontalPadding={{ lg: 's3', sm: 's1' }}
      paddingRight={{ lg: 'default', md: 'default', sm: 'unset' }}
    >
      <Cell>
        <Link to={routePaths.dashboard}>
          <FlexContainer alignItems="center" height="100%">
            <Icon
              color="white"
              cursor="pointer"
              height={{ lg: '3rem', sm: '2rem' }}
              name="logo"
              width={{ lg: '12rem', sm: '9rem' }}
            />
          </FlexContainer>
        </Link>
      </Cell>

      <Cell>
        <Grid
          alignItems="center"
          columnGap="1.5rem"
          columns="repeat(2, max-content)"
          gap="unset"
          justifyContent="flex-end"
          justifyItems="flex-end"
          padding="unset"
          paddingRight={{ lg: 's1', md: 'unset' }}
        >
          <BoxContainer hidden={{ lg: false, md: true }}>
            <Dropdown canShowDropdown overlay={menu}>
              <StyledDesignerLogoContainer>
                {!!userData?.designerInfo?.logoUrl ? (
                  <Image
                    alt=""
                    fallbackColor="transparent"
                    height={40}
                    source="dynamic"
                    src={getFileUrl<'designers'>({
                      directory: 'designers',
                      fileName: userData?.designerInfo?.logoUrl,
                      fileType: 'logo',
                      identifiers: { userId: userData.id },
                    })}
                    width={40}
                  />
                ) : (
                  <span />
                )}

                <TypographyWithIcon
                  color="white"
                  icon={
                    <Icon
                      color="white"
                      height="1rem"
                      left="-1px"
                      name="chevron-down"
                      top="1px"
                    />
                  }
                  iconPosition="right"
                  text={userData?.firstName || userData?.email}
                />
              </StyledDesignerLogoContainer>
            </Dropdown>
          </BoxContainer>

          <Dropdown
            overlay={
              <NotificationsOverlay
                notifications={userData?.designerInfo?.notifications || []}
              />
            }
          >
            <Icon
              color="rgb(143, 142, 139)"
              left="1px"
              name="bell"
              size="2.4rem"
              top="2px"
            />
          </Dropdown>

          <StyledMenuIconContainer onClick={onMenuButtonClick}>
            <StyledMenuIconLine active={sideMenuVisible} />
            <StyledMenuIconLine active={sideMenuVisible} />
          </StyledMenuIconContainer>
        </Grid>
      </Cell>
    </Grid>
  );
};
